import React, { useContext } from 'react';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import { IdiomContext } from "../../context/createcontext"
import { useTranslation } from 'react-i18next';

export default function Terminos() {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    return (
        <div>
            <Header></Header>

            <div className='container mb-5' style={{ marginTop: '120px' }}>
                <div className='flex justify-content-center'  >
                    <b><h1 className='text-3xl'> {t("aLegal.aLegal")}</h1></b>
                </div>
                <div className='legaltext container pt-3 pb-3'>
                    <h2 className='legaltitle mb-2'>{t("aLegal.responsable")}</h2>
                    <p><span className='font-semibold'>{t("aLegal.titular")}:</span>  AIRCO2 ESPACIO FINTECH, S.L.</p>
                    <p><span className='font-semibold'>CIF:</span> B-67677930</p>
                    <p><span className='font-semibold'>{t("aLegal.tel")}:</span>  <a href="tel:+34689794117" className='' aria-label='redirige a mandar un mail' >
                    (+34) 689 794 117
                        </a></p>

                    <p className='flex gap-1'>
                        <span className='font-semibold'>E-mail: </span>
                        <a href="mailto:lopd@airco2.eu" className='' aria-label='redirige a mandar un mail' >
                            lopd@airco2.eu
                        </a>
                    </p>
                    <br />
                </div>
                <div className='legaltext container pt-3 pb-3 '>
                    <p>{t("aLegal.txtLegal1")}<a href="mailto:lopd@airco2.eu" className='' aria-label='redirige a mandar un mail' >
                        lopd@airco2.eu
                    </a></p>
                    <p>{t("aLegal.txtLegal2")}</p>
                    <p>{t("aLegal.txtLegal3")}</p>
                    <p>{t("aLegal.txtLegal4")}</p>
                </div>

                <div className='legaltext container pt-3 pb-3'>
                    <h2 className='legaltitle mb-2'>{t("aLegal.condicionesGenerales")}</h2>
                    <p>{t("aLegal.txtCondiciones1")}</p>
                    <p>{t("aLegal.txtCondiciones2")}</p>
                    <p>{t("aLegal.txtCondiciones3")}</p>
                </div>

                <div className='legaltext container pt-3 pb-3'>
                    <h2 className='legaltitle  mb-2'>{t("aLegal.responsabilidad")} </h2>
                    <p>{t("aLegal.propietarioLista")}</p>
                    <ul className='px-4 my-1'>
                        <li type='circle'>{t("aLegal.propLista1")}</li>
                        <li type='circle'>{t("aLegal.propLista2")}</li>
                        <li type='circle' >{t("aLegal.propLista3")}</li>

                    </ul>
                    <p>{t("aLegal.proptxt")}</p>
                    <p>{t("aLegal.proptxt2")}</p>


                </div>
                <div className='legaltext container pt-3 pb-3'>
                    <h2 className='legaltitle mb-2'>{t("aLegal.pIntelectual")}</h2>
                    <p>{t("aLegal.pIntelectualtxt1")}</p>
                    <p>{t("aLegal.pIntelectualtxt2")}</p>




                </div>




            </div>
            <Footer></Footer>
        </div>
    )
}
