import { useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../context/createcontext";
import BloqueClientes from './componentsHome/BloqueClientes';

import befla from '../../images/befla.webp';
import coolberries from '../../images/coolfarming2.webp';
import coverwind from '../../images/coverwind.webp';
import frutas from '../../images/frutas-nieves.webp';
import ontime from '../../images/ontime.webp';
import ptmar from '../../images/pereztorres.webp';
import Footer from '../../partials/Footer';
import Header from '../../partials/Header';

import bg_img_frutasnieves from '../../images/fruit-3408683_640.webp';
import bg_img_beflamboyant from '../../images/success_stories_beflamboyant.webp';
import bg_img_coolberries from '../../images/success_stories_coolfarming.webp';
import bg_img_ontime from '../../images/success_stories_ontime.webp';
import bg_img_pereztorres from '../../images/success_stories_perez_torres.webp';
import bg_img_coverwind from '../../images/wind-mills-6928590_640.webp';


export default function Component() {

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const clients = [
    { id: 1, name: 'Ontime', sector: t("clientes.logistica"), logo: ontime, img: bg_img_ontime, text: t("newHome.successStories-Ontime") },
    { id: 2, name: 'Cool Berries', sector: t("clientes.agricultura"), logo: coolberries, img: bg_img_coolberries, text: t("newHome.successStories-CoolFarming"), clientPage: "/clientes/cool-berries" },
    { id: 3, name: 'Pérez Torres Marítima', sector: t("clientes.logistica"), logo: ptmar, img: bg_img_pereztorres, text: t("newHome.successStories-PerezTorres"), clientPage: "/clientes/perez-torres-maritima" },
    { id: 4, name: 'Frutas Nieves', sector: t("clientes.alimentacion"), logo: frutas, img: bg_img_frutasnieves, text: t("clientes.descipcionFrutasNieves") },
    { id: 5, name: 'Beflamboyant', sector: t("clientes.textil"), logo: befla, img: bg_img_beflamboyant, text: t("newHome.successStories-Beflamboyant") },
    { id: 6, name: 'Coverwind Solutions', sector: t("clientes.renovables"), logo: coverwind, img: bg_img_coverwind, text: t("clientes.descripcionCoverwind") },
  ];

  const sectors = [t("clientes.todos"), ...Array.from(new Set(clients.map((client) => client.sector)))];
  const [selectedSector, setSelectedSector] = useState(t("clientes.todos"));
  const filteredClients = selectedSector === t("clientes.todos") ? clients : clients.filter((client) => client.sector === selectedSector);
  const url = "https://links.bastidafarina.com/widget/bookings/reunion-30-source-web-airco2";

  return (
    <div>
      <Header></Header>
      {/* Hero Section */}
      <section className="sec-green-background py-5 text-white text-center">
        <div className="'max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 mt-5 ">
          <h1 className="display-4" data-aos="zoom-y-out">{t("clientes.heroTitle")} <span className="sec-blue-text">{t("clientes.huella")}</span></h1>
          <p className="lead mt-4">
            {t("clientes.heroSubtitle1")}
          </p>
          <p className="lead mt-4">
            {t("clientes.heroSubtitle2")}
          </p>
          <a className="btn btn-banner-o mt-4 shadow-sm" href={url} target='_blank'>
            {t("clientes.btnagendademo")}
          </a>
        </div>
      </section>

      <section className='bg-gray-50'>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 sec-blue-text">
          <div className='clientNumberBlock'>
            <div className='clientsSimpleNumbers' data-aos="fade-up">
              <div style={{ fontSize: '2.7rem', lineheight: '52.8px', fontWeight: '950' }}>+100</div>
              <p>{t("clientes.totalClientes")}</p>
            </div>
            <div className='clientsSimpleNumbers' data-aos="fade-up">
              <div style={{ fontSize: '2.7rem', lineheight: '52.8px', fontWeight: '950' }}>7</div>
              <p>{t("clientes.totalSectores")}</p>
            </div>
            <div className='clientsSimpleNumbers' data-aos="fade-up">
              <div style={{ fontSize: '2.7rem', lineheight: '52.8px', fontWeight: '950' }}>+5M</div>
              <p>{t("clientes.totalToneladas")}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white ">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-5 pb-5 text-airco2-general-grey" data-aos="zoom-in-up">
          <div>
            <h2 className="h2 mb-4">{t("clientes.nuestrosClientes")}</h2>
            <div className="mb-4 overflow-x-auto whitespace-nowrap items-center" >
              {sectors.map((sector) => (
                <button
                  key={sector}
                  onClick={() => setSelectedSector(sector)}
                  className={`btn-sm mb-1 inline-block text-airco2-general-grey ${selectedSector === sector ? 'font-bold' : ''}`}
                  style={{ borderBottom: selectedSector === sector ? '2px solid #878787' : '1px solid #f2f2f2' }}
                >
                  {sector}
                </button>
              ))}
            </div>
            <Row className="g-4">
              {filteredClients.map((client) => (
                <Col sm={6} md={4} key={client.id}>
                  <Card className={`bg-gray-50 h-full ${client.clientPage && 'card-client-detail'} `} onClick={() => {
                    if (client.clientPage) {
                      navigate(client.clientPage);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth' 
                      });
                    }
                  }}>
                    <div className='relative'>
                      <img src={client.img} alt="Cliente " />
                      <div className='bg-white absolute px-1' style={{ width: '85px', height: '55px', top: '5px', right: '5px', borderRadius: '5px' }} >
                        <div className='w-full h-full' style={{ backgroundImage: `url(${client.logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} />
                      </div>
                    </div>
                    <div style={{ padding: '1rem 1rem' }}>
                      <div className='font-cabinet-grotesk font-bold text-md sec-blue-text'><span>{client.name}</span> {selectedSector == t("clientes.todos") && <span className='text-rigth font-light text-xs'> &#40;{client.sector}&#41;</span>} </div>
                      <div className="text-airco2-general-grey" style={{ height: '150px', maxHeight: '150px' }}>
                        <p className="">{client.text}</p>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </section>

      <BloqueClientes />

      <div className='mt-5' />

      <Footer></Footer>
    </div>

  );
}
