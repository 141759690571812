import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import UserService from "../../../services/UserService";
import { IdiomContext } from "../../../context/createcontext";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ForestListTable from "./ForestListTable";
import { Spinner } from "react-bootstrap";
import PaginationClassic from "../PaginationClassic";
import DeleteButton from "../utils/DeleteButton";
import FilterButton from "../utils/DropdownFilter";
import ButtonAddForest from "./BtnAddForest";

function ForestList() {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [productsMP, setProductsMP] = useState({
    productsMPData: [],
    isFetching: false,
  });
  // const [infoProvider,setInfoProvider] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  // const {provider} = useContext(ProviderContext)

  const [montes, setMontes] = useState({
    montesData: [],
    isFetching: false,
  });
  const [editmontes, setEditMontes] = useState('');
  const statusName = (props) => {
    switch (props) {
      case 'CREATED':
        return t("forestList.sCreated");
      case 'VALIDATED':
        return t("forestList.sValidated");
      case 'VALIDATEDANDCERTIFICATED':
        return t("forestList.sValidatedAndCertificated");
      case 'ACTIVE':
        return t("forestList.sActive");
      case 'DISABLED':
        return t("forestList.sDisabled");
    }
  };

  // useEffect(() =>{
  //     fetch(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`, {
  //     method: "GET",
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': `Bearer ${UserService.getToken()}`
  //     }
  //   })
  //   .then((response) => {
  //     return response.json();
  //   })
  //   .then((infoProvider) => {
  //     if (!infoProvider.error) {
  //       setInfoProvider(infoProvider);
  //     }
  //   });
  // },[]) 


  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        setProductsMP({ productsMPData: productsMP.productsMPData, isFetching: true });
        const response = await axios.get(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}/${UserService.getUserId()}/products/externalids`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setProductsMP({ productsMPData: response.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
        setProductsMP({ productsMPData: productsMP.productsMPData, isFetching: false });
      } finally {
        setLoading(false)
      }

    };
    fetchProductsData();
  }, []);
  // }, [infoProvider]);



  useEffect(() => {
    const fetchMontesData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FOREST}${UserService.getUserId()}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setMontes({ montesData: response.data, isFetching: true });
      } catch (exception) {
        console.error(exception);
        setMontes({ montesData: montes.montesData, isFetching: true });
      } finally {
        setLoading(false)
      }

    };
    fetchMontesData();
  }, [editmontes]);


  const montesdata = montes.montesData;
  const montesbystate = montesdata.filter(v => v.state != null).sort((a, b) => b.creationDate.localeCompare(a.creationDate));;

  const stateUniq = [];
  montesbystate.map((v) => {
    if (stateUniq.indexOf(v.state) === -1) {
      stateUniq.push(v.state)
    }
  });

  const stateList = [];
  stateUniq.map((v) => {
    stateList.push({
      category: v,
      check: false,
      status: statusName(v)
    });
  })

  const [stateCheck, setCheck] = useState([])
  useEffect(() => {
    setCheck([...stateList]);
  }, []);

  const applyCategoryFilter = (data) => {
    setCheck([...data]);
    updateTableLines(0, itemPage, data)
    setCurrentPage(0)
  };

  // END CATEGORIES 
  const updateTableLines = (firstIndex, itemPage, data) => {
    if (data == null) {
      data = stateCheck;
    }
    let states = data.filter(c => c.check).map(c => c.category);

    let filteredList = [...datosApi];

    if (states.length > 0) {
      filteredList = filteredList.filter((c) => states.includes(c.state));
    }

    setTotalElement(filteredList.length);
    setLastPage(Math.ceil((filteredList.length) / itemPage) - 1)
    setItems(filteredList.splice(firstIndex, itemPage));

  };



  // PAGINACIÓN
  const [currentpage, setCurrentPage] = useState(0);
  const itemPage = process.env.REACT_APP_ITEMS_PER_PAGE;

  const [datosApi, setdatosApi] = useState(montes.montesData)
  const [totalElement, setTotalElement] = useState(montes.montesData.length)
  const [lastPage, setLastPage] = useState(Math.ceil((montes.montesData.length) / itemPage) - 1)

  const [items, setItems] = useState([...montesbystate].splice(0, itemPage))
  useEffect(() => {
    setdatosApi(montes.montesData);
    setTotalElement(montes.montesData.length);
    setLastPage(Math.ceil((montes.montesData.length) / itemPage) - 1)
    setItems(montes.montesData.slice(currentpage * itemPage, itemPage));
  }, [montes.montesData]);


  const nextHandler = () => {
    const nextpage = currentpage + 1;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(nextpage)
  }


  const prevHandler = () => {
    const prevPage = currentpage - 1;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(prevPage)
  }

  const firstHandler = () => {
    updateTableLines(0, itemPage)
    setCurrentPage(0)
  }

  const lastHandler = () => {
    updateTableLines(lastPage * itemPage, itemPage)
    setCurrentPage(lastPage)
  }

  const pointFirst = () => {
    const prevPage = currentpage - 2;
    if (prevPage < 0) return;
    const firstIndex = prevPage * itemPage;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(prevPage)
  }

  const pointLast = () => {
    const nextpage = currentpage + 2;
    const firstIndex = nextpage * itemPage;
    if (firstIndex >= totalElement) return;
    updateTableLines(firstIndex, itemPage)
    setCurrentPage(nextpage)
  }


  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Content */}


        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("forestList.mis-montes")}</h1>
                {/* <h2 className='text-xl md:text-2xl text-gray-800 font-bold pl-2'>$47,347.09</h2> */}
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                <DeleteButton selectedItems={selectedItems} />
                {/* Dropdown */}
                {/* <DateSelect /> */}
                {/* Filter button */}
                <FilterButton align="right"
                  categoriesUniq={stateUniq}
                  stateCheck={stateList}
                  applyCategoryFilter={applyCategoryFilter}
                />

                <ButtonAddForest />

              </div>

            </div>

            {/* <div className="alert alert-warning alert-dismissible fade show" role="alert">
              <b>AVISO:</b> Si tu monte está en País Vasco o Navarra contacta con nuestro<a href="mailto:forestal@airco2.eu" className="font-semibold"> equipo forestal.</a>
            </div> */}

            <p className="text-justify text-sm">{t("forestList.info")} </p>

            {/* Table */}
            {/* <OrdersTable selectedItems={handleSelectedItems} /> */}

            {/* Pagination */}
            <div className="mt-8">

              {/* Table */}
              {!montes.isFetching && <span>  <Spinner animation="border" className='flex justify-center' /></span>}
              {montes.isFetching &&
                <>  {montes.montesData.length === 0 && items.length === 0 ? <p> {t("sidebar.nomontes")}.</p>
                  :
                  <ForestListTable
                    selectedItems={handleSelectedItems}
                    montes={items}
                    setEditMontes={setEditMontes}
                    currentpage={currentpage}
                    idsExternosProductsMP={productsMP.productsMPData}
                  />}
                </>
              }

              {/* Pagination */}
              <div className="mt-8 ">
                {montes.montesData.length === 0 ?
                  "" :
                  <PaginationClassic
                    itemPage={itemPage}
                    currentpage={currentpage}
                    items={items}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    total={montesbystate}
                    firstHandler={firstHandler}
                    lastHandler={lastHandler}
                    lastPage={lastPage}
                    pointFirst={pointFirst}
                    pointLast={pointLast}
                  />

                }
              </div>

            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default ForestList;