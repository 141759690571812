
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import ExcelComponent from './ExcelComponent';
import InputsComponent from './InputsComponent';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalTitle, ModalFooter } from "react-bootstrap";
import FileViewer from './FileViewer';
import logo from '../../../images/Logo verde_airCO2.png'
import InputsModalComponent from './InputsModalComponent';

function InfoForm({ questBlock, sendDataToParent, idCalculation, addAnswer, isButtonDisabled, selectedBlockId, year, orgName, status, thirdPartyForm, selectedBlockName }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [showSendButton, setShowSendButton] = useState(true);
  const [inputData, setInputData] = useState(true);
  const [colLength, setColLength] = useState(true);
  const [radioButtonsData, setRadioButtonsData] = useState({ current: { text: "", id: "" }, other: { text: "", id: "" } });
  const [radioButtonsTotal, setRadioButtonsTotal] = useState(null);
  const [excelAvailable, setExcelAvailable] = useState();
  const [invoiceProcessorState, setInvoiceProcessorState] = useState(null);
  const [inputsRendered, setInputsRendered] = useState(false);
  const [extractedData, setExtractedData] = useState();
  const [idAddedAnswer, setIdAddedAnswer] = useState();

  const invoiceProcessStates = {
    NOTUPLOADED: 'NOTUPLOADED',
    ERROR: 'ERROR',
    UPLOADING: 'UPLOADING',
    UPLOADED: 'UPLOADED',
    SENT: 'SENT',
    COMPLETED: 'COMPLETED',
    SUCCESS: 'SUCCESS',
    SAVEKO: 'SAVEKO'
  };

  useEffect(() => {
    if (invoiceProcessorState === invoiceProcessStates.COMPLETED) {
      setInputsRendered(true);
    }
  }, [invoiceProcessorState]);

  //Si cambia  de pregunta
  useEffect(() => {
    // setSelectedOption(null);
    document.getElementById("footprint-form")?.reset();

    setInputData(questBlock?.data?.inputs?.sort(function (a, b) {
      return a.position - b.position;
    }));
    setColLength(questBlock?.data?.inputs?.length > 4 ? 4 : 12 / questBlock?.data?.inputs?.length);

    questBlock?.data?.inputs?.forEach(function (objeto) {
      if (objeto.input.inputType === 'RADIOBUTTONS') {
        setShowSendButton(false);
        return;
      } else {
        setShowSendButton(true);
      }
    });
  }, [questBlock])


  const addAnswer1 = (props) => {
    addAnswer(props);
    if (modalExcel) {
      toggleExcelModal();
    }

    if (invoiceReaderModal) {
      setIdAddedAnswer(props)
    }
  };

  const processState = (props) => {
    setInvoiceProcessorState(props);
  };

  //Enviar al padre el idQuestion para mostrar la info de la pregunta elegida (km/combustible)
  const handleConfirmClick = () => {
    let allOptionRadioBtn = document.querySelectorAll('input[name="dataRadioButton"]');
    let saveRadioButtonsInfo = { current: { text: "", id: "" }, other: { text: "", id: "" } };

    allOptionRadioBtn.forEach(element => {
      if (element.checked) {
        saveRadioButtonsInfo.current.text = element.id;
        saveRadioButtonsInfo.current.id = element.value;
      } else {
        saveRadioButtonsInfo.other.text = element.id;
        saveRadioButtonsInfo.other.id = element.value;
      }
    });


    setRadioButtonsData(saveRadioButtonsInfo)
    sendDataToParent(saveRadioButtonsInfo.current.id)
    setRadioButtonsTotal(allOptionRadioBtn.length)
  }

  //Reset estados usados para gestionar radiobuttons
  useEffect(() => {
    setRadioButtonsData({ current: { text: "", id: "" }, other: { text: "", id: "" } });
    setRadioButtonsTotal(null)
  }, [selectedBlockId])



  const backToRadioButtons = () => {
    sendDataToParent(radioButtonsData.other.id);
    setRadioButtonsData({ current: radioButtonsData.other, other: radioButtonsData.current })
  }

  const [modalExcel, setModalExcel] = useState(false);
  const toggleExcelModal = () => setModalExcel(!modalExcel);

  const [invoiceReaderModal, setInvoiceReaderModal] = useState(false);
  const toggleInvoiceReaderModal = () => setInvoiceReaderModal(!invoiceReaderModal);

  const getInvoiceProcessorState = (props) => {
    setInvoiceProcessorState(props.state);
    setExtractedData(props.extractedData);
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 830) {
        setExcelAvailable(false);
      } else {
        setExcelAvailable(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const showFileUploaded = (props) => {
    addAnswer("");
  }

  return (
    <>
      <Modal show={modalExcel} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
        <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
          <ModalTitle className=''>{t("footPrint.modalExcelTitle")}</ModalTitle>
          <button onClick={toggleExcelModal}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </button>
        </ModalHeader>
        <ModalBody className='xl-modal-body'>
          <ExcelComponent
            questBlock={questBlock}
            idCalculation={idCalculation}
            inputData={inputData}
            addAnswer1={addAnswer1}
            showSendButton={showSendButton}
            isButtonDisabled={isButtonDisabled}
          />
        </ModalBody>
      </Modal>

      <Modal show={invoiceReaderModal} className='xl-modal' animation={false} data-aos="fade-up" fullscreen backdropClassName="xl-modal-backshadow">
        <ModalHeader className='xl-modal-header sec-neutro-background sec-grey-text text-lg flex justify-between '>
          <ModalTitle className=''>Leer datos desde una factura</ModalTitle>
          <button onClick={() => { toggleInvoiceReaderModal(); setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED); }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </button>
        </ModalHeader>
        <ModalBody className='xl-modal-body'>
          <div className='flex flex-row'>
            <div className='col-7'>
              <FileViewer getInvoiceProcessorState={getInvoiceProcessorState} invoiceProcessState={invoiceProcessorState} inputData={inputData} inputsRendered={inputsRendered} idAddedAnswer={idAddedAnswer} showFileUploaded={showFileUploaded} />
            </div>
            <div className='col-5 flex justify-center items-center'>
              {(invoiceProcessorState == invoiceProcessStates.NOTUPLOADED || invoiceProcessorState == null) && "Selecciona una factura "}
              {invoiceProcessorState == invoiceProcessStates.ERROR && <div className='flex flex-col space-y-4'><span>Vaya... ha ocurrido un error procesando el archivo</span><button onClick={() => { setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED); }} className='btn btn-sn btn-green'>Subir otra factura</button></div>}
              {invoiceProcessorState == invoiceProcessStates.SAVEKO && <div className='flex flex-col space-y-4'><span>Vaya... ha ocurrido un al guardar los datos</span><button onClick={() => { setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED); }} className='btn btn-sn btn-green'>Intentarlo de nuevo</button></div>}
              {invoiceProcessorState == invoiceProcessStates.UPLOADED &&
                <div className='flex items-center flex-col space-y-4'>
                  <button className='btn btn-green' onClick={() => setInvoiceProcessorState(invoiceProcessStates.UPLOADING)}>Leer factura</button>
                  <hr className="w-1/2 "></hr>
                  <button onClick={() => setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED)}>Seleccionar otro archivo diferente</button>
                </div>}
              {(invoiceProcessorState == invoiceProcessStates.SUCCESS &&
                <div className='flex flex-col space-y-4'>
                  <div className='flex items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="26" height="26" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" stroke="#8dd4bd">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                      <path d="M9 12l2 2l4 -4"></path>
                    </svg>Datos guardados correctamente</div>
                  <button onClick={() => { setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED); }} className='btn btn-sm btn-green'>Subir otra factura</button>
                  <button onClick={() => { toggleInvoiceReaderModal(); setInvoiceProcessorState(invoiceProcessStates.NOTUPLOADED); }} className='btn btn-sm btn-cancel text-white'>Cerrar</button>
                </div>)}

              {invoiceProcessorState == invoiceProcessStates.UPLOADING &&

                <div className="flex h-full justify-center items-center sec-white-background ">
                  <div className="relative ">
                    <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />
                    <div className="text-center text-xl absolute font-semibold sec-grey-text mb-4" style={{ top: "150px", left: "85px" }}>
                      Analizando factura...
                    </div>
                    <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                      <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>}
              {invoiceProcessorState == invoiceProcessStates.COMPLETED &&
                <InputsModalComponent
                  questBlock={questBlock}
                  idCalculation={idCalculation}
                  addAnswer1={addAnswer1}
                  isButtonDisabled={isButtonDisabled}
                  inputData={inputData}
                  showSendButton={showSendButton}
                  colLength={12}
                  processState={processState}
                  year={year}
                  extractedData={extractedData}
                />}
            </div>

          </div>
        </ModalBody>
      </Modal>

      {questBlock?.length === 0 || inputData === undefined ?
        <div className='flex justify-center'>
          <Spinner animation="border" variant="dark" className="m-4" />
        </div>

        :

        <div className='text-airco2-general mt-4'>



          {inputData.length > 1 && inputData[0].input.inputType !== "RADIOBUTTONS" &&
            <div className={`${inputData == 0 && "hidden"}`}>

              <div>
                <div id="custom-list-container">
                  {inputData.every((inputObj) => inputObj.input.inputType !== "RADIOBUTTONS") && (
                    <div>
                      <div className='flex flex-wrap justify-between items-center gap-2'>
                        <div>
                          <h1 className="mb-1  text-lg  text-gray-800 font-bold">{selectedBlockName}</h1>
                        </div>
                        <div className='flex gap-2'>
                          {excelAvailable && <button
                            onClick={toggleExcelModal}
                            className='btn-sm btn-green text-xs'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-spreadsheet  mr-1" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                              <path d="M8 11h8v7h-8z" />
                              <path d="M8 15h8" />
                              <path d="M11 11v7" />
                            </svg>
                            {t("footPrint.btnCargarExcel")}
                          </button>}
                          <button onClick={toggleInvoiceReaderModal}
                            className='btn-sm btn-cancel  text-xs'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-pdf mr-1" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                              <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                              <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                              <path d="M17 18h2" />
                              <path d="M20 15h-3v6" />
                              <path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
                            </svg>
                            {t("footPrint.readFromInvoice")}
                            </button>
                        </div>
                      </div>

                      <InputsComponent
                        questBlock={questBlock}
                        idCalculation={idCalculation}
                        addAnswer1={addAnswer1}
                        isButtonDisabled={isButtonDisabled}
                        inputData={inputData}
                        showSendButton={showSendButton}
                        colLength={colLength}
                        status={status}
                        year={year}
                        orgName={orgName}
                        thirdPartyForm={thirdPartyForm}
                      />

                    </div>)}
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

export default InfoForm;