import axios from 'axios';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import secure from './../../../images/secure_login.svg';
import BannerCertificate from './BannerCertificate';
import BannerEquivalencia from './BannerEquivalencia';
import BannerNoData from './BannerNoData';
import BannerRecalculate from './BannerRecalculate';
import BannerSectorComparation from './BannerSectorComparation';
import BannerTutoriales from './BannerTutoriales';
import ButtonsCalculationBlock from './ButtonsCalculationBlock';
import DownloadExcelModal from './DownloadExcelModal';
import DownloadReportModal from './DownloadReportModal';
import TotalCalculoBlock from './TotalCalculoBlock';
import EmissionByQuestion from './graficas/EmissionsByQuestions';
import FootprintOrganizationBuilding from './graficas/FootprintOrganizationBuildings';
import PartialsByQuestions from './graficas/PartialsByQuestions';
import QuestionsEmissionsFactors from './graficas/QuestionsEmissionsFactors';
import ReportSummary from './graficas/ReportSummary';
import Scope from './graficas/Scope';
import GraficaTop from './graficas/TopEmissions';
import ActivityIndex from './graficas/cards/ActivityIndex';
import Combustible from './graficas/cards/Combustible';
import Electrical from './graficas/cards/Electrical';
import EmployeBilling from './graficas/cards/EmployeBilling';

export default function CalculoReporte() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const pathParams = useParams();
    const idCalculation = pathParams.id;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showReport, setShowReport] = useState(false);
    const handleCloseReport = () => setShowReport(false);
    const handleShowReport = () => setShowReport(true);
    const [textModal, setTextModal] = useState("footPrint.quieroCertificar")
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showExcel, setShowExcel] = useState(false);
    const handleCloseExcel = () => setShowExcel(false);
    const handleShowExcel = () => setShowExcel(true);

    const [calculation, setCalculation] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchCalculation = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setCalculation({ data: response.data.data, isFetching: true });
                if (response.data.data.viewResults) {
                    fetchReport();
                    fetchHigh();
                    fetchTop();
                    fetchEmissionsFactors();
                    fetchStamp();
                } else {
                    fetchHigh();
                }
            } catch (exception) {
                setCalculation({ data: [], isFetching: true });
            }
        };
        fetchCalculation();
    }, []);

    const [report, setReport] = useState({
        data: [],
        isFetching: false
    });
    const fetchReport = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setReport({ data: response.data, isFetching: true });
        } catch (exception) {
            setReport({ data: [], isFetching: true });
        }
    };

    const [totalCO2eByReportData, setTotalCO2eByReportData] = useState(0);
    useEffect(() => {
        if (report.data && report.isFetching) {
            let total = 0;
            report?.data?.data?.forEach(item => {
                total += item.totalKgCO2e;
            });
            setTotalCO2eByReportData(total)
        }
    }, [report.data])


    const handleEditCalculation = (calculationObjetc) => {
        navigate('/footprint/finalized', { state: { calculationOutputDto: { data: calculationObjetc } } });
    }

    //DATOS HIGHLIGHTS
    const [highLights, setHighLights] = useState({
        data: [],
        isFetching: false
    });

    const fetchHigh = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/highlights`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setHighLights({ data: response.data?.data, isFetching: true });
        } catch (exception) {
            setHighLights({ data: [], isFetching: true });
        }
    };

    const formatNumber = (value, decimals) => {
        if (typeof value === 'number') {
            return value.toFixed(decimals);
        } else {
            return undefined;
        }
    }

    //DATOS TOP EMISSIONS
    const [topFactors, setTopFactors] = useState({
        data: [],
        isFetching: false
    });
    const fetchTop = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/top-emission-factors`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setTopFactors({ data: response.data, isFetching: true });
        } catch (exception) {
            setTopFactors({ data: [], isFetching: true });
        }
    };


    //DATOS QUESTIONS EMISSION FACTORS
    const [emissionsFactors, setEmissionsFactors] = useState({
        data: [],
        isFetching: false
    });
    const fetchEmissionsFactors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/questions-emission-factors`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setEmissionsFactors({ data: response.data, isFetching: true });
        } catch (exception) {
            setEmissionsFactors({ data: [], isFetching: true });
        }
    };


    //DATOS STAMP 
    const [urlStamp, setUrlStamp] = useState(null);
    const fetchStamp = async () => {
        fetch(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/stamp`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
            })
            .then(j => j.json())
            .then(data => {
                if (data != undefined && data.data != null) {
                    setUrlStamp(data.data);
                    return null;
                }
            })
    }

    //DATOS REPORT
    const [loadingReport, setLoadingReport] = useState(false);
    const [loadingReportCommitments, setLoadingReportCommitments] = useState(false);

    const [loadingReportGhg, setLoadingReportGhg] = useState(false);
    const [loadingReportIso, setLoadingReportIso] = useState(false);
    const handleReport = async (parametro = null, reducctionCommitments = false) => {
        try {
            if (parametro === 'GHG') {
                setLoadingReportGhg(true);
            } else if (parametro === 'ISO') {
                setLoadingReportIso(true);
            } else {
                if (reducctionCommitments) {
                    setLoadingReportCommitments(true)
                } else {
                    setLoadingReport(true);
                }
            }



            let url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report/pdf`;
            if (reducctionCommitments) {
                url += `?withReducctionCommitments=true`;
            }
            if (parametro !== null) {
                url += `?withReducctionCommitments=true&reportTableType=${parametro}`;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
            });
            if (response.status === 200) {
                const reporte = await response.json();

                if (reporte != undefined && reporte.data != null) {
                    let urlToOpen;
                    if (parametro === "ISO") {
                        urlToOpen = reporte.data.urlReportISO;
                    } else if (parametro === "GHG") {
                        urlToOpen = reporte.data.urlReportGHG;
                    } else {
                        urlToOpen = reporte.data.urlReport;
                    }
                    if (urlToOpen) {
                        window.open(urlToOpen, '_blank');
                    }
                }
            }
        } catch (error) {
            console.error('Error al obtener el reporte:', error);
        } finally {
            if (parametro === 'GHG') {
                setLoadingReportGhg(false);
            } else if (parametro === 'ISO') {
                setLoadingReportIso(false);
            } else {
                if (reducctionCommitments) {
                    setLoadingReportCommitments(false)
                } else {
                    setLoadingReport(false);
                }
            }
        }
    };

    //DATOS EXCEL
    const [loadingExcel, setLoadingExcel] = useState({ excel: false, zip: false });
    const handleExcelReport = async (zip) => {
        try {

            if (zip) {
                setLoadingExcel({ excel: false, zip: true });
            } else {
                setLoadingExcel({ excel: true, zip: false });
            }

            const response = await fetch(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report/excel?zipFiles=${zip ? 'true' : 'false'}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
            });
            if (response.status === 200) {
                const reporte = await response.json();
                if (reporte != undefined && reporte.data != null) {
                    if (zip) {
                        window.open(reporte.data.urlExcelReportZipFiles, '_blank');
                    } else {
                        window.open(reporte.data.urlExcelReport, '_blank');
                    }
                }
            }
        } catch (error) {
            console.error('Error al obtener el reporte:', error);
        } finally {
            setLoadingExcel({ excel: false, zip: false });
        }
    };


    const handleEmailCertification = async () => {
        setButtonDisabled(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/certification?emailTo=${UserService.getUsername()}&nameTo=${UserService.getName()}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                },
            });

            if (response.status === 200) {
                setTextModal("footPrint.quieroCertificarGracias");
                const email = await response.json();
                if (email !== undefined && email.data !== null) {
                    setCalculation(prevCalculation => ({
                        ...prevCalculation,
                        data: {
                            ...prevCalculation.data,
                            data: {
                                ...prevCalculation.data.data,
                                hasRequestedCertification: true,
                            },
                        },
                        isFetching: true,
                    }));
                }
            } else {
                throw new Error('La solicitud no se completó correctamente');
            }
        } catch (error) {
            console.error('Error al enviar el email:', error);
        } finally {
            setButtonDisabled(false);
        }
    };


    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {(calculation?.data?.calculationStatus === 'DRAFT' && calculation?.data?.year < new Date().getFullYear() - 1) &&
                    // <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">
                    //     <h1>{t("footPrint.noReporte")}</h1>
                    // </div>


                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="font-bold text-2xl">{t('footPrint.calculoOrganizacion')}{calculation?.data?.year}</h1>

                            </div>
                        </div>

                        <div className='sec-neutro-background'>
                            <div className='container text-center'>
                                <img width={250} className="imgs mb-2" src={secure}></img>

                                <p>{t("footPrint.noReporte")}</p>


                                <div className="flex items-center justify-between mt-5">

                                    <div className='container text-center '><Link to="/footprint/myfootprint" className="btn sec-green-background hover:sec-green-background text-white ml-auto">{t("footPrint.volver")} </Link></div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
                {(calculation?.data?.calculationStatus !== 'DRAFT' || calculation?.data?.year >= new Date().getFullYear() - 1) &&
                    <main>
                        <DownloadReportModal
                            calculation={calculation}
                            showReport={showReport}
                            handleReport={handleReport}
                            loadingReport={loadingReport}
                            loadingReportCommitments={loadingReportCommitments}
                            loadingReportGhg={loadingReportGhg}
                            loadingReportIso={loadingReportIso}
                            handleCloseReport={handleCloseReport}
                            idCalculation={idCalculation}
                        >
                        </DownloadReportModal>

                        <DownloadExcelModal
                            calculation={calculation}
                            showExcel={showExcel}
                            handleExcelReport={handleExcelReport}
                            loadingExcel={loadingExcel}
                            handleCloseExcel={handleCloseExcel}>
                        </DownloadExcelModal>

                        {!calculation.isFetching && <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">
                            <div>{t("footPrint.infoCalculo")}</div>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" />
                        </div>}

                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">
                            {calculation.isFetching &&
                                <BannerRecalculate calculation={calculation?.data}></BannerRecalculate>
                            }

                            {calculation.isFetching &&
                                <div className='md:flex justify-between'>   {/* Page header */}
                                    <div className=' font-bold text-2xl'>{t('footPrint.calculoOrganizacion')}{calculation?.data?.year}</div>
                                    <div className=' '>
                                        {(calculation?.data?.finishDate) &&
                                            <div className='flex justify-start md:justify-end font-semibold text-sm gap-1'>
                                                <span>{t('footPrint.realizado')}</span>
                                                <span>
                                                    {format(new Date(calculation?.data?.finishDate), 'dd/MM/yyyy')}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>}


                            {(calculation.isFetching && !calculation?.data?.viewResults) &&
                                <div className="flex flex-wrap">
                                    <BannerNoData></BannerNoData>

                                    <Electrical highLights={highLights} formatNumber={formatNumber} />

                                    <Combustible highLights={highLights} formatNumber={formatNumber} />
                                </div>
                            }

                            {(calculation.isFetching && calculation?.data?.viewResults) &&
                                <div>

                                    <div className='grid-report-header '>
                                        {/* BLOQUE 1, HUELLA, CARDS, DESCARGAS */}
                                        {/* Dato total */}
                                        <div className="square-grid-report-footprint-total">
                                            <TotalCalculoBlock
                                                calculation={calculation}
                                                urlStamp={urlStamp}
                                                totalCO2eByReportData={totalCO2eByReportData}></TotalCalculoBlock>
                                        </div>

                                        <div className="flex flex-wrap">
                                            {(calculation.data.iaNumber && calculation.data.iaUnit && calculation.data.iaDescription) &&
                                                <ActivityIndex calculation={calculation} />

                                            }


                                            <EmployeBilling highLights={highLights} formatNumber={formatNumber} />


                                            <Electrical highLights={highLights} formatNumber={formatNumber} />

                                            <Combustible highLights={highLights} formatNumber={formatNumber} />
                                        </div>

                                        <div className="square-grid-report-footprint">
                                            <ButtonsCalculationBlock calculation={calculation}
                                                handleEditCalculation={handleEditCalculation}
                                                handleShowExcel={handleShowExcel}
                                                handleShowReport={handleShowReport}
                                                loadingExcel={loadingExcel}
                                                loadingReport={loadingReport}
                                                urlStamp={urlStamp}></ButtonsCalculationBlock>
                                        </div>

                                    </div>


                                    <div style={{ clear: "both" }}></div>

                                    {/* BLOQUE 2, BANNER CERTIFICADO, BANNER TUTORIALES */}
                                    <div className='' >

                                        <BannerCertificate calculation={calculation}
                                            handleShow={handleShow}
                                            show={show}
                                            handleClose={handleClose}
                                            handleEmailCertification={handleEmailCertification}
                                            buttonDisabled={buttonDisabled}
                                            textModal={textModal} />

                                        <BannerTutoriales
                                        />

                                    </div>




                                    {/* BLOQUE 3, BANNER EQUIVALENCIA */}
                                    <div className='mb-4'>
                                        <BannerEquivalencia totalHuella={calculation?.data?.totalKgCO2e / 1000} />
                                    </div>
                                    <div className='mb-4'>
                                        <BannerSectorComparation facturacion={calculation?.data?.annualTurnover}
                                            totalCO2e={calculation?.data?.totalKgCO2e}
                                            sectorId={calculation?.data?.organization?.service?.id} />
                                    </div>


                                    {/* Content */}
                                    {/* BLOQUE 4, IMG ALCANCE Y GRAFICA ALCANCES PARCIALES */}
                                    <div className="grid grid-cols-12 gap-6 mb-4">
                                        <ReportSummary report={report?.data?.data} />
                                        <PartialsByQuestions data={report?.data?.data} />

                                        {/* <ScopeByEmission scope1={scope1} scope2={scope2} scope3={scope3} sumsByScope={sumsByScope} data={report?.data?.data}> </ScopeByEmission> */}
                                    </div>


                                    {/* BLOQUE 5, GRAFICAS REDONDDAS */}
                                    <div className="grid grid-cols-12 gap-6 mb-4 ">
                                        <Scope report={report?.data?.data} />
                                        <FootprintOrganizationBuilding report={report?.data?.data} />
                                    </div>

                                    {/* BLOQUE 6, TOTAL KG POR PREGUNTA */}
                                    <div className='mb-4'>
                                        <EmissionByQuestion data={report?.data?.data} />
                                    </div>

                                    {/* BLOQUE 7, TOP FACTORES */}
                                    <div className='mb-4 flex flex-col gap-6'>
                                        <GraficaTop topFactors={topFactors?.data?.data} />
                                    </div>

                                    {/* BLOQUE 8,EMISIONES FACTORES POR PREGUNTA */}
                                    <div className='mb-4'>
                                        <QuestionsEmissionsFactors emissionsFactors={emissionsFactors?.data.data} />
                                    </div>
                                </div>

                            }
                        </div>

                    </main>
                }
            </div>
        </div>
    )
}
