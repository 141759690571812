import React from "react";


export default function tableGreatPackaging() {
  return (
    <div className="table-responsive rounded-lg my-3">
      {/* <table className=" table table-dark table-striped  bg-white text-xs table-auto w-full "> */}
      <table className=" table bg-white text-xs table-auto w-full ">
        <thead className="border-b">
          <tr className="text-gp-green ">
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Lote</th>
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Fecha fabricación</th>
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Cantidad</th>
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Toneladas Emisión</th>
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Nº Proyecto de compensación</th>
            <th className="pl-2 text-lg-gp first:pl-5 last:pr-5 pl-3 py-3 text-center" >Link de proyecto de compensación
            </th>

          </tr>
        </thead>
        <tbody className="text-lg-gp text-gp-lightgrey">
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
          <tr>
            <td className="text-center">#001-1331000000</td>
            <td className="text-center">14.09.2023</td>
            <td className="text-center">9909</td>
            <td className="text-center">847</td>
            <td className="text-center"><a href="desarga">847</a></td>
            <td className="text-center"><a href="https://www.bosques-amazonicos.com/nosotros/" target="_blank">Bosques Amazónicos BAM en Perú</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
