import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import UserService from '../../../services/UserService';
import Header from '../Header';
import Sidebar from '../Sidebar';
import BannerEquivalencia from './BannerEquivalencia';
import BannerSectorComparation from './BannerSectorComparation';
import TotalCalculoBlock from './TotalCalculoBlock';
import FootprintOrganizationsOrgaization from './graficas/FootprintOrganizationsOrgaization';
import ReportSummary from './graficas/ReportSummary';
import Scope from './graficas/Scope';
import ScopeBarOrganizations from './graficas/ScopeBarOrganizations';
import Combustible from './graficas/cards/Combustible';
import Electrical from './graficas/cards/Electrical';

export default function CalculoReporteYear() {
    // const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const pathParams = useParams();
    const year = pathParams.year;


    const [calculation, setCalculation] = useState({
        data: [],
        isFetching: false
    });

    const [calculations, setCalculations] = useState({
        data: [],
        isFetching: false
    });
    const [scopes, setScopes] = useState({
        data: [],
        isFetching: false
    });

    const [indiceFacturacion, setIndiceFacturacion] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchCalculation = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULO}?userId=${UserService.getUserId()}&year=${year}&calculationStatus=FINALIZED`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const sumObject = response.data.data.reduce((acc, curr) => {
                    acc.totalEmployees = (acc.totalEmployees || 0) + curr.employees;
                    acc.totalAnnualTurnover = (acc.totalAnnualTurnover || 0) + curr.annualTurnover;
                    acc.totalKgCO2e = (acc.totalKgCO2e || 0) + curr.totalKgCO2e;
                    acc.totalKgCO2eScope1 = (acc.totalKgCO2eScope1 || 0) + curr.totalKgCO2eScope1;
                    acc.totalKgCO2eScope2 = (acc.totalKgCO2eScope2 || 0) + curr.totalKgCO2eScope2;
                    acc.totalKgCO2eScope3 = (acc.totalKgCO2eScope3 || 0) + curr.totalKgCO2eScope3;
                    return acc;
                }, {});

                const dataByScopes = response.data.data.map((item) => {
                    return [
                        { scope: 1, totalKgCO2e: item.totalKgCO2eScope1 },
                        { scope: 2, totalKgCO2e: item.totalKgCO2eScope2 },
                        { scope: 3, totalKgCO2e: item.totalKgCO2eScope3 },
                    ];
                });

                const agrupadoOrganizacionPorServicios = Object.values(response.data.data.reduce((acumulador, item) => {
                    const serviceId = item.organization.service?.id;

                    // Si no existe una entrada para este serviceId, la creamos
                    if (!acumulador[serviceId]) {
                        acumulador[serviceId] = {
                            serviceId: serviceId,
                            annualTurnover: 0,
                            totalKgCO2e: 0,
                        };
                    }

                    // Sumamos los valores correspondientes
                    acumulador[serviceId].annualTurnover += item.annualTurnover || 0;
                    acumulador[serviceId].totalKgCO2e += item.totalKgCO2e;

                    return acumulador;
                }, {}));

                // Actualizamos el estado con los datos originales y el objeto de suma
                setCalculation({ data: sumObject, isFetching: true });
                setCalculations({ data: response.data.data, isFetching: true });
                setScopes({ data: dataByScopes.flat(), isFetching: true });
                setIndiceFacturacion({ data: agrupadoOrganizacionPorServicios, isFetching: true })
                fetchStamp(response.data.data[0]?.id);
            } catch (exception) {
                console.error(exception);
                setCalculation({ data: [], isFetching: true });
                setCalculations({ data: [], isFetching: true });

            }
        };
        fetchCalculation();
    }, []);


    const [totalCO2eByReportData, setTotalCO2eByReportData] = useState(0);


    const handleEditCalculation = (calculationObjetc) => {
        navigate('/footprint/finalized', { state: { calculationOutputDto: { data: calculationObjetc } } });
    }

    const handleReportButton = () => {
        if (!loadingReport) {
            fetchReportMultiorganization();
        }
    }

    const [loadingReport, setLoadingReport] = useState(false)
    const fetchReportMultiorganization = async () => {
        setLoadingReport(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/report/pdf/${year}`, {}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            window.open(response.data?.data.urlReport, '_blank');
        } catch (exception) {
        }
        setLoadingReport(false);

    };


    // DATOS HIGHLIGHTS
    const [allAnsweredQuestions, setAllAnsweredQuestions] = useState({
        data: [],
        isFetching: false
    });
    useEffect(() => {
        const fetchAllAnsweredQuestions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULATION}/report/${year}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const resultado = {
                    electricalConsumption: {},
                    combustibleConsumption: {}
                };
                response.data?.data.filter(item => item.questionType === "ELECTRIC_POWER_MARKETER")

                    // 2. Agrupar por "factor" y sumar "kgCO2"
                    .reduce((grupos, item) => {
                        const factor = item.factor;
                        grupos[factor] = grupos[factor] || { quantity: 0, kgCO2e: -Infinity };
                        grupos[factor].quantity += item.totalKgCO2e;

                        // Actualizar maxKgCO2e si se encuentra un valor mayor
                        if (item.totalKgCO2e > grupos[factor].kgCO2e) {
                            grupos[factor].kgCO2e = item.totalKgCO2e;
                            grupos[factor].name = item.factor;
                        }

                        return grupos;
                    }, resultado.electricalConsumption)

                let maxQuantityFactor = null;
                let maxQuantity = -Infinity;

                for (const factor in resultado.electricalConsumption) {
                    const grupo = resultado.electricalConsumption[factor];
                    if (grupo.quantity > maxQuantity) {
                        maxQuantity = grupo.quantity;
                        maxQuantityFactor = factor;
                    }
                }

                if (maxQuantityFactor) {
                    resultado.electricalConsumption = {
                        name: maxQuantityFactor,
                        quantity: resultado.electricalConsumption[maxQuantityFactor].quantity,
                        kgCO2e: resultado.electricalConsumption[maxQuantityFactor].kgCO2e
                    };
                } else {
                    resultado.electricalConsumption = {
                        name: null,
                        quantity: 0,
                        kgCO2e: 0
                    };
                }


                response.data?.data.filter(item => item.questionType === "FIXED_INSTALLATIONS"
                    || item.questionType === "COMBUSTIBLE_CONSUMED" || item.questionType === "ROAD_TRANSPORT_DISTANCE"
                    || item.questionType === "ROAD_TRANSPORT_EXPENSE" || item.questionType === "SOIL_TILLAGE")

                    // 2. Agrupar por "factor" y sumar "kgCO2"
                    .reduce((grupos, item) => {
                        const factor = item.factor;
                        grupos[factor] = grupos[factor] || { quantity: 0, kgCO2e: -Infinity };
                        grupos[factor].quantity += item.totalKgCO2e;

                        // Actualizar maxKgCO2e si se encuentra un valor mayor
                        if (item.totalKgCO2e > grupos[factor].kgCO2e) {
                            grupos[factor].kgCO2e = item.totalKgCO2e;
                            grupos[factor].name = item.factor;
                        }

                        return grupos;
                    }, resultado.combustibleConsumption)

                let maxQuantityFactorComb = null;
                let maxQuantityComb = -Infinity;

                for (const factor in resultado.combustibleConsumption) {
                    const grupo = resultado.combustibleConsumption[factor];
                    if (grupo.quantity > maxQuantityComb) {
                        maxQuantityComb = grupo.quantity;
                        maxQuantityFactorComb = factor;
                    }
                }

                if (maxQuantityFactorComb) {
                    resultado.combustibleConsumption = {
                        name: maxQuantityFactorComb,
                        quantity: resultado.combustibleConsumption[maxQuantityFactorComb].quantity,
                        kgCO2e: resultado.combustibleConsumption[maxQuantityFactorComb].kgCO2e
                    };
                } else {
                    resultado.combustibleConsumption = {
                        name: null,
                        quantity: 0,
                        kgCO2e: 0
                    };
                }

                setAllAnsweredQuestions({ data: resultado, isFetching: true });
            } catch (exception) {
                setAllAnsweredQuestions({ data: [], isFetching: true });
            }
        }; fetchAllAnsweredQuestions();
    }, []);


    const formatNumber = (value, decimals) => {
        if (typeof value === 'number') {
            return value.toFixed(decimals);
        } else {
            return undefined;
        }
    }


    // //DATOS STAMP 
    const [urlStamp, setUrlStamp] = useState(null);
    const fetchStamp = async (calculationId) => {
        if (calculationId) {
            fetch(`${process.env.REACT_APP_CH_CALCULATION}/${calculationId}/stamp`,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    },
                })
                .then(j => j.json())
                .then(data => {
                    if (data != undefined && data.data != null) {
                        setUrlStamp(data.data);
                        return null;
                    }
                })
        }
    }

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    {(!calculation.isFetching || !calculations.isFetching) && <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">
                        <div>{t("footPrint.infoCalculo")}</div>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" />
                    </div>}

                    {(calculation.isFetching && calculations.isFetching) && <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center">

                        <div className='md:flex justify-between'>
                            <div className=' font-bold text-2xl'> {year} {calculations.data.map(c => c.organization.name).join(', ')}</div>
                            <div className=' '>
                                {(calculation?.data?.finishDate) &&
                                    <div className='flex justify-start md:justify-end font-semibold text-sm gap-1'>
                                        <span>{t('footPrint.realizado')}</span>
                                        <span>
                                            {format(new Date(calculation?.data?.finishDate), 'dd/MM/yyyy')}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div>

                            <div className='grid-report-header '>
                                {/* BLOQUE, HUELLA, CARDS, DESCARGAS */}

                                {/* Dato total */}
                                <div className="square-grid-report-footprint-total">
                                    <TotalCalculoBlock
                                        calculation={calculation}
                                        urlStamp={urlStamp}
                                        totalCO2eByReportData={totalCO2eByReportData}></TotalCalculoBlock>
                                </div>

                                <div className="flex flex-wrap">
                                    <Electrical highLights={allAnsweredQuestions} formatNumber={formatNumber} />
                                    <Combustible highLights={allAnsweredQuestions} formatNumber={formatNumber} />
                                </div>

                                <div className="square-grid-report-footprint-total">

                                    <div onClick={handleReportButton} className={`box-report-footprint rounded-sm border border-gray-200`}>
                                        <div className='p-2 flex flex-col items-center'>
                                            <div className='mb-2'>
                                                {!loadingReport && <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#545a6d" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                                </svg>}
                                                {loadingReport && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />}
                                            </div>
                                            <p className="text-xs text-center">{t("footPrint.downReporte")}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div style={{ clear: "both" }}></div>

                            {/* BLOQUE, BANNER EQUIVALENCIA VUELOS */}
                            <div className='mb-4'>
                                <BannerEquivalencia totalHuella={calculation?.data?.totalKgCO2e / 1000} />
                            </div>

                            {/* BLOQUE, BANNER EQUIVALENCIA SECTOR */}
                            {indiceFacturacion.data.map(item => (!isNaN(item.annualTurnover) && item.annualTurnover > 0 && (
                                <div key={item.serviceId} className='mb-4'>
                                    {console.log(item)}
                                    <BannerSectorComparation facturacion={item.annualTurnover}
                                        totalCO2e={item.totalKgCO2e}
                                        sectorId={item.serviceId} />
                                </div>)))}

                            {/* BLOQUE, Gráficas sumatorio por alcance y organización */}
                            <div className="grid grid-cols-12 gap-6 mb-4">
                                <ReportSummary report={scopes?.data} />
                                <ScopeBarOrganizations calculations={calculations.data} />

                            </div>

                            {/* BLOQUE, Dato de organización, comparación de alcances por organización */}
                            <div className="grid grid-cols-12 gap-6 mb-4 ">
                                <Scope report={scopes?.data} />
                                <FootprintOrganizationsOrgaization report={calculations?.data} />
                            </div>

                        </div>
                    </div>
                    }
                </main>
            </div>
        </div>
    )
}
