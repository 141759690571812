import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "../../../../context/createcontext";

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function DecarbonizationChart({ resume }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");


    useEffect(() => {
        if (!resume) return;

        const years = Array.from({ length: resume.targetYear - resume.baseYear + 1 }, (_, i) => resume.baseYear + i);

        const evolutionScope1 = calcularValoresAnuales(resume.tco2eScopes1and2, resume.currentScopes1and2, resume.baseYear, resume.targetYear);
        const evolutionScope3 = calcularValoresAnuales(resume.tco2eScope3, resume.currentScope3, resume.baseYear, resume.targetYear);

        const varEvolutionScope1and2 = calcularValoresAnuales(resume.tco2eScopes1and2, resume.targetScopes1and2, resume.baseYear, resume.targetYear);
        const varEvolutionScope3 = calcularValoresAnuales(resume.tco2eScope3, resume.targetScope3, resume.baseYear, resume.targetYear);

        const scope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2');
        const scope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-light');
        const evolutionScope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution');
        const evolutionScope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution-light');

        const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
        const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');
        const evolutionscope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution');
        const evolutionScope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution-light');

        const data = {
            labels: years,
            datasets: [
                {
                    label: t("decarbonization.scopes1and2"),
                    data: evolutionScope1,
                    backgroundColor: scope1and2Color,
                    hoverBackgroundColor: scope1and2LightColor,
                    stack: 'a',
                    borderWidth: 1,
                    order: 3,
                    fill: false,

                },
                {
                    label: t("decarbonization.scope3"),
                    data: evolutionScope3,
                    backgroundColor: scope3Color,
                    hoverBackgroundColor: scope3LightColor,
                    stack: 'b',
                    borderWidth: 1,
                    order: 3,
                    fill: false,

                },
                {
                    label: t("decarbonization.targetScopes1and2"),
                    data: varEvolutionScope1and2,
                    type: 'line',
                    borderColor: evolutionScope1and2Color,
                    backgroundColor: evolutionScope1and2LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2
                },
                {
                    label: t("decarbonization.targetscope3"),
                    data: varEvolutionScope3,
                    type: 'line',
                    borderColor: evolutionscope3Color,
                    backgroundColor: evolutionScope3LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2
                },
            ],
        };


        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: false,
                        beginAtZero: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, resume]);



    function calcularValoresAnuales(valorBase, reduccionTotal, anioInicial, anioFinal) {
        const numAnios = anioFinal - anioInicial;
        const tasaReduccionAnual = 1 - Math.pow((1 - (reduccionTotal / 100)), 1 / numAnios);

        const valoresAnuales = [valorBase]; // Iniciar con el valor base

        for (let i = 1; i <= numAnios; i++) {
            const valorAnterior = valoresAnuales[i - 1];
            const valorActual = valorAnterior * (1 - tasaReduccionAnual);
            valoresAnuales.push(valorActual);
        }

        return valoresAnuales;
    }

    return (
        <React.Fragment>
            {!resume && <div className="text-center"><Spinner className='' /></div>}
            {!canvas !== null && resume && <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas>}
        </React.Fragment>
    );


}