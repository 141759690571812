import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import Translations from '../../../services/Translations';
import UserService from '../../../services/UserService';
import provincias from "./../data/provincias.json";
import { id } from 'date-fns/locale';
export default function FormMontesMapMultiple({ newForest, dataParcelas, sendParcel2Delete, dataParcelasEdit, infoParcelasEdit }) {

  const montePadre = newForest;
  const infoParcelasSelec = dataParcelasEdit ? [...dataParcelasEdit, ...dataParcelas] : dataParcelas;




  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [disableButton, setDisableButton] = useState(false);
  const [showButtons, setShowButtons] = useState("");
  const [showNew, setShowNew] = useState("hidden");
  const [showForm, setShowForm] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [inputSpecies, setInputSpecies] = useState("");
  const [modalMixto, setModalMixto] = useState(false);
  const [idsForestDelete, setIdsForestDelete] = useState([]);
  const [refCatastralDelete, setRefCatastralDelete] = useState([])

  //Búsqueda de provincia según la referencia catastral
  const provincia = (props) => {
    const matchingProvince = provincias.find((v) => props.slice(0, 2) === v.cod);

    return matchingProvince ? matchingProvince.name : undefined;
  }


  const totalHa = infoParcelasSelec
    ?.filter((parcela) => !refCatastralDelete.includes(parcela.cadastralReference))
    .reduce((accumulator, currentObj) => {
      return accumulator + parseInt(currentObj.polygonSurface);
    }, 0);



  //Estado con todas las especies de BBDD
  const [forestType, setForestType] = useState({
    forestTypeData: [],
    isFetching: false,
  });

  useEffect(() => {
    const fetchForestType = async () => {
      try {
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: true });
        await axios.get(`${process.env.REACT_APP_FORESTTYPE}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          setForestType({ forestTypeData: response.data.sort((x, y) => x.commonName.localeCompare(y.commonName)), isFetching: false });
          // setItems(response.data);
        });
      } catch (exception) {
        console.error(exception);
        setForestType({ forestTypeData: forestType.forestTypeData, isFetching: false });
      }
    };
    fetchForestType();
  }, []);

  //Estado para los mensajes de los banners
  const [formMontesMessage, setFormMontesMessage] = useState({
    type: '',
    message: ''
  })

  //Estado que guarda el objeto del monte con los nuevos valores
  const [forestUpdated, setForestUpdated] = useState({
    forestUpdatedData: [],
    isFetching: false,
  });

  //Estado que guarda el objeto con las url al subir gml al GC
  const [gmlUpload, setGmlUpload] = useState({
    gmlUploadData: [],
    isFetching: false,
  });

  const cabecera = "<FeatureCollection xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:gml='http://www.opengis.net/gml/3.2' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:cp='http://inspire.ec.europa.eu/schemas/cp/4.0' xmlns:gmd='http://www.isotc211.org/2005/gmd' xsi:schemaLocation='http://www.opengis.net/wfs/2.0 http://schemas.opengis.net/wfs/2.0/wfs.xsd http://inspire.ec.europa.eu/schemas/cp/4.0 http://inspire.ec.europa.eu/schemas/cp/4.0/CadastralParcels.xsd' xmlns='http://www.opengis.net/wfs/2.0' timeStamp='2022-12-15T15:53:02' numberMatched='1' numberReturned='1'>";
  const pie = "</FeatureCollection>"

  const [rows, setRows] = useState();
  // //Hadler del Submit del formulario
  const handlerSubmit = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    // Borrar las parcelas del check
    await Promise.all(
      idsForestDelete.map(async (forestId) => {
        const response = await axios.delete(
          `${process.env.REACT_APP_DELETE_FOREST}${forestId}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: `Bearer ${UserService.getToken()}`
            }
          }
        );

        if (response.status !== 200) {
          console.error(`Error al eliminar el bosque con ID ${forestId}`);
          throw new Error('Error en eliminación');
        }
      })
    );
    if ((totalHa / 10000).toFixed(2) >= 2) {
      setFormMontesMessage({ ...formMontesMessage, type: "warning", message: "Actualizando datos..." });
      document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
      const idsToDelete = idsForestDelete.map((forestId) => String(forestId));

      let members = cabecera;
      let newRows = [];
      let isUpdated = false;

      infoParcelasSelec.forEach((v, i) => {
        const idElement = document.getElementById("idChild-" + i);
        const currentId = idElement ? idElement.innerHTML : null;
        const shouldExclude = idsToDelete.includes(currentId);
        if (!shouldExclude) {

          let gmlcompleto = v.gmlString;
          let gmlsinincio = gmlcompleto.substr(622);
          let member = gmlsinincio.slice(0, -20);
          members += member;

          if (i == 0) {
            let row = {
              "id": montePadre.id,
              "owner": montePadre.owner,
              "size": document.getElementById("hectareas-" + i).innerHTML,
              "cadastre": document.getElementById("catastral-" + i).innerHTML,
              "forestType": document.getElementById("especie-" + i).value,
              "age": document.getElementById("edad-" + i).value,
              "marco": null,
              "name": montePadre.name,
              "creationDate": new Date(),
              "userId": UserService.getUserId(),
              "province": document.getElementById("provincia-" + i).value,
              "state": "CREATED",
              "urlGml": "",
              "idPadre": "",
              "hubspot_negocioId": montePadre.hubspot_negocioId
            };
            newRows.push(row);
          } else {
            if (!isUpdated && infoParcelasEdit) {
              isUpdated = true;
            }
            // const idElement = document.getElementById("idChild-" + i);
            let row = {
              "owner": montePadre.owner,
              "id": idElement ? idElement.innerHTML : null,
              "size": document.getElementById("hectareas-" + i).innerHTML,
              "cadastre": document.getElementById("catastral-" + i).innerHTML,
              "forestType": document.getElementById("especie-" + i).value,
              "age": document.getElementById("edad-" + i).value,
              "marco": null,
              "name": montePadre.name,
              "creationDate": new Date(),
              "userId": UserService.getUserId(),
              "province": document.getElementById("provincia-" + i).value,
              "state": "CREATED",
              "urlGml": "",
              "idPadre": montePadre.id
            };

            newRows.push(row);
          }
        }

      });
      members += pie;
      setRows(newRows);

      fetchGmlUpload(newRows, members, isUpdated);

      e.target.reset()
      setShowButtons("hidden");
      setShowForm("hidden");
      setShowNew("block");
    } else {

      setModal(true);
    }
    //setDisableButton(false);
  }

  //Endpoint que guarda el Gml en GC y llama al endpoint que actualiza el monte con la url del cloud storage
  const fetchGmlUpload = async (newRows, members, isUpdated) => {
    const fileData = members;
    const blob = new Blob([fileData], { type: "text/xml" });

    const formData = new FormData();
    formData.append("files", blob, newRows[0].cadastre + ".gml");
    try {
      setGmlUpload({ gmlUploadData: gmlUpload.gmlUploadData, isFetching: true });
      await axios.post(`${process.env.REACT_APP_CLOUDSTORAGE_GML}` + UserService.getUserId(), formData, {
        headers: {
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
        // body:JSON.stringify(monte)
      }).then(response => {
        if (response.status === 200) {
          //Completa el atributo urlGml de la primera parcelacon la url que acaba de darnos el GC storage
          newRows[0].urlGml = response.data[0].filePublicUrl;
          fetchForestUpdated(newRows, isUpdated);
        } else {
          fetchForestUpdated(newRows, isUpdated);
        }
        setGmlUpload({ gmlUploadData: response.data, isFetching: false });
        // setItems(response.data);
      });
    } catch (exception) {
      console.eror(exception);
      fetchForestUpdated(newRows, isUpdated); //TODO: lo volvemos a intentar en el catch por algo?
      setGmlUpload({ gmlUploadData: gmlUpload.gmlUploadData, isFetching: false });
    }
  };

  //Llammada al endpoint que guarda el array de montes
  const fetchForestUpdated = async (newRows, isUpdated) => {
    try {
      setForestUpdated({ forestUpdatedData: forestUpdated.forestUpdatedData, isFetching: true });
      await axios.post(`${process.env.REACT_APP_MULTI_FOREST}?email=${UserService.getUsername()}&isUpdated=${isUpdated}`, newRows, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
        // body:JSON.stringify(monte)
      }).then(response => {
        if (response.status === 200) {
          setFormMontesMessage({ ...formMontesMessage, type: "success", message: t("account.actualizacion-ok") });
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        } else {
          setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
          document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
        }
        setForestUpdated({ forestUpdatedData: response.data, isFetching: false });
      });
    } catch (exception) {
      console.error(exception);
      setFormMontesMessage({ ...formMontesMessage, type: "error", message: t("account.actualizacion-ko") })
      document.getElementById('h1newmonte')?.scrollIntoView({ behavior: 'smooth' });
      setForestUpdated({ forestUpdatedData: forestUpdated.forestUpdatedData, isFetching: false });
    }
  };

  const reload = () => {
    setShowButtons("block")
    setShowNew("hidden")
    setShowForm("block")
    setFormMontesMessage({ ...formMontesMessage, type: "", message: "" })
  }

  const goList = () => { window.location.href = "/forest/forestlist" }

  function refreshPageDelete() {
    const fetchDeleteForest = async () => {
      try {
        await axios.delete(`${process.env.REACT_APP_DELETE_FOREST}${montePadre.id}`, {}, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        }).then(response => {
          window.location.reload(false);
        });
      } catch (exception) {
        window.location.reload(false);
      }
    };
    fetchDeleteForest();
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const handleDelete = function (props) {
    sendParcel2Delete(props);
  };




  const handleAddDelete = (forestId, refCatastral) => {

    const isInList = idsForestDelete.includes(forestId);


    setIdsForestDelete((prevIdsForest) => {

      return isInList ? prevIdsForest.filter(id => id !== forestId) : [...prevIdsForest, forestId];
    });

    const isInLisCat = refCatastralDelete.includes(refCatastral);
    setRefCatastralDelete((prevCatrastral) => {
      return isInLisCat ? prevCatrastral.filter(cadastralReference => cadastralReference !== refCatastral) : [...prevCatrastral, refCatastral];
    });

  }


  useEffect(() => {
    if (inputSpecies === "Mixto ") {
      setModalMixto(true);
    }
  }, [inputSpecies]);


  return (
    <React.Fragment>


      <Modal show={modalMixto}>
        <Modal.Header className='sec-green-background text-white text-lg'><b>{t("formMonte.alert_mixto_title")}</b></Modal.Header>
        <Modal.Body className='text-left'>
          {t("formMonte.alert_mixto0")} <a href="mailto:forestal@airco2.eu" className='font-semibold' >{t("formMonte.alert_mixto1")}</a>{t("formMonte.alert_mixto2")}<br />{t("formMonte.alert_mixto3")}
        </Modal.Body>
        <div className='flex justify-center pb-2'>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => setModalMixto(false)}>
            {t("formMonte.alert_cerrar")}
          </Button>
        </div>
      </Modal>
      <Modal show={modal} toggle={toggle}>
        <Modal.Header toggle={toggle}>{t("formMonte.alert_error")}</Modal.Header>
        <Modal.Body className='text-center'>
          {t("formMonte.ha_insuficientes")}
        </Modal.Body>
        <div className='flex justify-center pb-2'>
          <Button className=" text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={toggle}>
            {t("formMonte.alert_cerrar")}
          </Button>
        </div>
      </Modal>




      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} id="form_multimonte">

        {/* Form */}
        <Form className={`sec-neutro-background ${showForm}`} style={{ width: '100%' }} onSubmit={handlerSubmit}>
          <div className="space-y-4">
            <div>
              <div className='flex'>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="32" height="32" viewBox="0 0 24 24"  stroke="var(--airco2-green-color)" fill="none">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                  <line x1="15" y1="21" x2="15" y2="18" />
                  <path d="M8 13l-2 -2" />
                  <path d="M8 12l2 -2" />
                  <path d="M8 21v-13" />
                  <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                </svg>
                <label className="block h4 underline ml-2" htmlFor="nombre">{montePadre == undefined ? "" : montePadre.name}</label>
              </div>
              <label className="block text-sm font-medium mb-1" htmlFor="titular">{t("formMonte.titular")}: {montePadre == undefined ? "" : montePadre.owner}</label>
            </div>

            <div className='relative overflow-x-auto'>
              <table className=" w-full divide-y divide-gray-200 ">
                <thead className="text-xs  text-gray-500  border-t border-gray-200">
                  <tr>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">Nº</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">{t("formMonte.catastral")}</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">Ha</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">{t("formMonte.provincia")}</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">{t("formMonte.especie")}</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">{t("forestList.años")}</div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-semibold text-center">{t("formMonte.delete")}</div>
                    </th>
                    <th>
                      <div className="font-semibold text-center"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {infoParcelasSelec?.length > 0 ?
                    infoParcelasSelec.map((v, i) => {
                      const correspondingEditParcel = infoParcelasEdit && infoParcelasEdit.find(editParcel => editParcel.cadastre === v.cadastralReference);
                      const smallestId = infoParcelasEdit && infoParcelasEdit.length > 0
                        ? Math.min(...infoParcelasEdit.map(parcel => parcel.id))
                        : null;
                      return (
                        <tr key={i}>
                          <td className="hidden" data-name="cadastre">
                            <div id={"idChild-" + i} name="idChild" className="text-center">{correspondingEditParcel?.id || ""}</div>
                          </td>

                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">{i + 1}</div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="cadastre">
                            <div id={"catastral-" + i} name="catastral" className="text-center">{v.cadastralReference}</div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="size">
                            <div id={"hectareas-" + i} name="hectareas" className="text-center">{(v.polygonSurface / 10000).toFixed(2)}</div>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="province">

                            {provincia(v.cadastralReference) == undefined ? 
                              <select defaultValue={correspondingEditParcel?.province || ""} id={"provincia-" + i} name="provincia" data-tip data-for="provinciaTip" className="form-input w-full" type="text" required >
                                <option name="Selecciona" disabled className='w-full' value="">Selecciona provincia</option>
                                {
                                  provincias.map((item) => {
                                    return (
                                      <option key={item.cod} name={item.name} className='w-full' value={item.name}>{item.name}</option>
                                    );
                                  })
                                }
                              </select>
                            :
                              <input id={"provincia-" + i} disabled value={provincia(v.cadastralReference)} name="provincia" className="sec-neutro-background text-center"></input>
                            }
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="forestType">
                            <div className="text-center">
                              <select
                                id={"especie-" + i}
                                defaultValue={correspondingEditParcel?.forestType || ""}
                                name="especie" data-tip data-for="especiesTip" className="form-input" type="text" onChange={e => setInputSpecies(e.target.value)} required >
                                <option name="Selecciona" disabled className='w-full' value="">{t("formMonte.selectespecie")}</option>
                                {
                                  forestType.forestTypeData.map((x) => {
                                    return (
                                      <option key={x.id} name={x.commonName} className='w-full' value={x.commonName} >{t(Translations.translateForestTypeName(x.commonName)) + " (" + x.scientificName + ")"}</option>
                                    );
                                  })
                                }
                              </select>
                              <ReactTooltip id="especiesTip" place="top" effect="solid">{t("formMonte.tooltip_mixto")}<br />{t("formMonte.alert_mixto3")}</ReactTooltip>

                            </div>
                          </td>

                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="age">
                            <div className="text-center">
                              {!correspondingEditParcel ?
                                <input id={"edad-" + i} name="edad" className="form-input " type='number' min="1" step=".5" placeholder="Edad aproximada" defaultValue={correspondingEditParcel?.age || ""} required={correspondingEditParcel ? false : true} />
                                :

                                < input id={"edad-" + i} name="edad" className="form-input " type='number' min="1" step=".5" placeholder="Edad aproximada" value={correspondingEditParcel?.age} />



                              }

                            </div>
                          </td>



                          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap" data-name="delete">
                            <div className='flex justify-center'>
                              <button className="align-center" type="button" onClick={() => handleDelete(v)}>
                                {!correspondingEditParcel && (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5"  stroke="var(--airco2-green-color)" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="4" y1="7" x2="20" y2="7" />
                                    <line x1="10" y1="11" x2="10" y2="17" />
                                    <line x1="14" y1="11" x2="14" y2="17" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                )}
                              </button>

                              {correspondingEditParcel && correspondingEditParcel.id !== smallestId && (




                                <input id="checkboxDelete" name="checkboxDelete" className="form-input flex mx-2" type='checkbox'
                                  onChange={() => handleAddDelete(correspondingEditParcel.id, v.cadastralReference)}

                                />


                              )}
                            </div>
                          </td>




                        </tr>)
                    })
                    : ""
                  }
                  <tr className="border-t border-gray-200">
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"></td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">TOTAL HA :</td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><b>{(totalHa / 10000).toFixed(2)}</b></td>
                    <td ></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='flex items-center justify-center text-center pl-4 pr-4'>
              <input id="checkbox" name="checkbox" className="form-input " type='checkbox' required />
              <label className="block text-xs font-medium mb-1 ml-1" htmlFor="marco">{t("formMonte.checkbox")}<span className="text-red-500">*</span></label>
            </div>


            <div className="flex items-center justify-center mt-6">
              <Button arial-label="btn-regristrar" type="submit" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`} disabled={totalHa >= 20000 ? false : true}>
                {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
                {t("formMonte.registrar")}
              </Button>

              <Button arial-label="btn-cancelar" type="reset" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap ${showButtons}`} onClick={dataParcelasEdit == undefined ? refreshPageDelete : ()=>goList()}>
                {t("formMonte.cancelar")}
              </Button>
            </div>

          </div>
        </Form>

        <div className="flex flex-col " style={{ height: '350px' }}></div>

        <div>
          <div>
            {formMontesMessage.type != "" && <Banner type={formMontesMessage.type} open setOpen={true}>{formMontesMessage.message}</Banner>}
          </div>
          <div className='flex items-center justify-center space-x-3 mt-4'>
            <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap ${showNew}`} onClick={refreshPage}>
              {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
              {t("forestList.newMonte")}
            </Button>
            <Button arial-label="btn-nuevo" className={`btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap ${showNew}`} onClick={() => goList()}>
              {/* <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap">Sign Up</Link> */}
              {t("forestList.mis-montes")}
            </Button>
          </div>
          <div className="pt-4 mt-6 border-t border-gray-200 text-center">
            <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/forest/dashboardmontes">{t("formMonte.volver")}</Link>
          </div>
        </div>
      </div >

    </React.Fragment >
  );
}
