import React from 'react'
import calculado from '../../../images/2024_sellos_calculado.webp'
import fondo from '../../../images/fondogp.png'
import imgBagsCustomer1 from '../../../images/img-cliente-bolsas1.svg'
import imgBagsCustomer2 from '../../../images/img-cliente-bolsas2.svg'
import logogp from '../../../images/LOGO GP BLANCO.COM.png'
import Header from '../../../partials/Header'
import LandingGreatPackaging from './landingGreatPackaging'

export default function gp() {
    return (<>
        <div className='hidden'><Header></Header></div>
        <div className='background'>
            <div className='flex flex-col' style={{
                backgroundImage: `url(${fondo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: '80vh',
            }}>
                <div className='flex justify-between pt-5 px-5 items-center'>
                    <div data-aos="flip-left" data-aos-delay="400"><img className='logoAirco2' src={calculado} style={{ maxWidth: "300px" }} alt="airCO2" /></div>
                    <div data-aos="flip-left" data-aos-delay="400"> <img className='logoGp' src={logogp} style={{ maxWidth: "175px" }} alt="GP" /></div>
                </div>
                <div className='flex justify-center grow' style={{ alignItems: 'center' }} data-aos="flip-down" data-aos-delay="400">
                    <div className="transparent-text px-2 pb-5" >
                        <h1><div className='bordered-text'>OFFSET YOUR CARBON</div>
                            <div className='bordered-text'>FOOTPRINT</div></h1>

                    </div>
                </div>
                <div className='flex justify-end pb-4 px-5 items-end '>
                    <div className="col-12 col-md-2">
                        <div className="flex gap-4 justify-end items-end">
                            <div data-aos="flip-left" data-aos-delay="400"><img
                                src={imgBagsCustomer1}
                                alt="img-bags"
                                style={{ maxHeight: "100px", minWidth: "100px" }}
                            /></div>
                            <div data-aos="flip-left" data-aos-delay="400"><img
                                src={imgBagsCustomer2}
                                alt="img-bags"
                                style={{ maxHeight: "100px", minWidth: "100px" }}
                            /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <LandingGreatPackaging></LandingGreatPackaging>
            </div>
            {/* <div className="container-great-packaging xl:mx-8 mt-4">
                <div className='flex justify-center' >
                    <img className='w-full max-w-3xl' src={imgFooter2}></img>
                </div>
            </div> */}
        </div>
    </>)
}
