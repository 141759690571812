import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import Banner from '../../../components/utils/Banner';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import { current } from 'tailwindcss/colors';


export default function ReductionCommitmentComponent({organizationName, calcualtionYear, idCalculation, isModal, updateDocumentation, idCertification }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  const [loading, setLoading] = useState(false)
  const startRef = useRef(null);
  const [reductionPercentages, setReductionPercentages] = useState(
    [{year: calcualtionYear + 1,
      scopes:{ scope1: "", scope2: "", scope3: "" }
    },
    {year: calcualtionYear + 2,
      scopes:{ scope1: "", scope2: "", scope3: "" }
    },
    {year: calcualtionYear + 3,
    scopes:{ scope1: "", scope2: "", scope3: "" }
    }
  ])


  const [reductionCommitment, setReductionCommitment] = useState({
    data: [],
    isFetching: true
  });

  const [reductionId, setReductionId] = useState({
    data: [],
    isFetching: true
  });

  useEffect(() => {
    const fetchReductionCommitment = async () => {
      const url = `${process.env.REACT_APP_CH_REDUCTION}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });

        setReductionCommitment({ data: response.data, isFetching: false });
      } catch (exception) {
        setReductionCommitment({ data: [], isFetching: false });
        console.error(exception.message)
      }

    };
    fetchReductionCommitment();
  }, []);

  useEffect(() => {
    const fetchReductionId = async () => {
      const url = `${process.env.REACT_APP_CH_REDUCTION_ID}${idCalculation}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });

        let data = response.data;
        let reductionYearasPercentage = data.data.find(item=> item?.reductionCommitmentDto == undefined && item?.text.startsWith('[{') );
        if(reductionYearasPercentage != undefined) {
          let newData = data.data.filter(x => x.id != reductionYearasPercentage.id );

          setReductionId({ data: { data : newData}, isFetching: false });
          setReductionPercentages(JSON.parse(reductionYearasPercentage.text));
        } else {
          setReductionId({ data: data, isFetching: false });

        }

      } catch (exception) {
        setReductionId({ data: [], isFetching: false });
        console.error(exception.message)
      }
    };
    fetchReductionId();
  }, []);

  function formatearTexto(texto) {
    let textoFormateado = texto.replace(/[\W_]/g, ' ').toLowerCase();
    textoFormateado = textoFormateado.charAt(0).toUpperCase() + textoFormateado.slice(1);
    return textoFormateado;
  }


  const handleReduction = async (e) => {
    e.preventDefault();
    if (errorSeleccion) {
      setRespuestaPost({ message: "No has seleccionado ningún compromiso", type: "warning" })
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_CH_REDUCTION_ID}${idCalculation}`;
        const selectedData = [];
        checkedItems.forEach((id) => {
          selectedData.push({
            "id": id,
          });
        });

        const contentState = editorState.getCurrentContent();
        const contentHtml = draftToHtml(convertToRaw(contentState));
        if (contentHtml.trim().length >  7) {
          selectedData.push({
            "text": contentHtml === "<p></p>\n" ? "" : contentHtml,
          });
        }

        console.log(!hasEmptyScopes())
        if (!hasEmptyScopes()) {
          selectedData.push({
            "text": JSON.stringify(reductionPercentages),
          });
        }

        const response = await axios.post(url, selectedData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });


        if (response.status === 200) {
          let data = response.data;
          let reductionYearasPercentage = data.data.find(item=> item?.reductionCommitmentDto == undefined && item?.text.startsWith('[{') );
          if(reductionYearasPercentage != undefined) {
            let newData = data.data.filter(x => x.id != reductionYearasPercentage.id );
  
            setReductionId({ data: { data : newData}, isFetching: false });
            setReductionPercentages(JSON.parse(reductionYearasPercentage.text));
          } else {
            setReductionId({ data: data, isFetching: false });
  
          }
          setRespuestaPost({ message: "Compromisos de reduccion añadidos correctamene", type: "success" })

        } else {
          setRespuestaPost({ message: "No se han podido añadir los compromisos de reducción", type: "warning" })
        }
        startRef.current.scrollIntoView({ behavior: 'smooth' });


      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const hasEmptyScopes = () => {
    for (let item of reductionPercentages) {
      const { scope1, scope2, scope3 } = item.scopes;
      if (scope1 === "" || scope2 === "" || scope3 === "") {
        return true; // Return the year with an empty scope
        break;
      }
    }
    return false; // All scopes are properly set
  };


  const useSortableData = (items, config = null) => {

    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      if (!items || items.length === 0) {
        return [];
      }
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB) * (sortConfig.direction === 'ascending' ? 1 : -1);
          }

          return (valueA - valueB) * (sortConfig.direction === 'ascending' ? 1 : -1);
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const { items, requestSort, sortConfig } = useSortableData(reductionCommitment.data.data);

  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });

  useEffect(() => {
    const reductionIdText = reductionId?.data?.data?.map((x) => x?.text).join("\n");

    if (reductionIdText) {
      const contentState = stateFromHTML(reductionIdText);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [reductionId?.data?.data]);



  const handleEditorStateChange = (newState) => {
    setEditorState(newState);
  };

  const [checkedItems, setCheckedItems] = useState(new Set(
    (reductionId?.data?.data || [])
      .filter((x) => x.reductionCommitmentDto && x.reductionCommitmentDto.id !== null)
      .map((x) => x.reductionCommitmentDto.id)
  ))

  useEffect(() => {
    if (!reductionId.isFetching) {
      setCheckedItems(new Set(
        (reductionId?.data?.data || [])
          .filter((x) => x.reductionCommitmentDto && x.reductionCommitmentDto.id !== null)
          .map((x) => x.reductionCommitmentDto.id)
      ));
    }
  }, [reductionId.isFetching]);


  const handleCheckboxChange = (id) => {
    setRespuestaPost({ message: "", type: "" })
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = new Set(prevCheckedItems);
      if (newCheckedItems.has(id)) {
        newCheckedItems.delete(id);
      } else {
        newCheckedItems.add(id);
      }
      return newCheckedItems;
    });
  };


  const [respuestaPost, setRespuestaPost] = useState({
    type: '',
    message: ''
  });


  const handleEditorChange = (newEditorState) => {}
  const [errorSeleccion, setErrorSeleccion] = useState(true)

  useEffect(()=>{
    let contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if(checkedItems.size > 0 || contentHtml.trim().length > 7){
      setErrorSeleccion(false);
    }else{
      setErrorSeleccion(true)
    }
  },[checkedItems,handleEditorChange])


  const [loadingReport, setLoadingReport] = useState(false);
  const handleReport = async () => {
      try {
          setLoadingReport(true);
          const response = await fetch(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/report/pdf?withReducctionCommitments=true`, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  // 'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${UserService.getToken()}`
              },
          });

          if (response.status === 200) {
              const reporte = await response.json();
              if (reporte != undefined && reporte.data != null) {
                  window.open(reporte.data.urlReportReductionCommitment, '_blank');
              }
          }
      } catch (error) {
          console.error('Error al obtener el reporte:', error);
      } finally {
          setLoadingReport(false);
      }
  };

  const [loadingPlan, setLoadingPlan] = useState({data: [], isFetching: false});
  const handleReductionPlan = async () => {
      try {
        setLoadingPlan({data: loadingPlan.data, isFetching: true});
        const response = await fetch(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${idCertification}/documentation/6/reduction-plan`, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  // 'Access-Control-Allow-Origin': '*',
                  'Authorization': `Bearer ${UserService.getToken()}`
              },
          });
          if (response.status === 200) {
            const data = await response.json();

            if (data) {
                updateDocumentation(data);
            }

            setLoadingPlan({ data, isFetching: false });
        } else {
            console.error('Error: Response was not OK', response.status);
            setLoadingPlan({ data: [], isFetching: false });
        }
      } catch (error) {
          console.error('Error al obtener el reporte:', error);
      } finally {
          setLoadingPlan({data: [], isFetching: false});
      }
  };

  

  const handleReductionPercentage = (e, year, scopeKey) => {
      const { value } = e.target;
  
      // Create a new array to update the state immutably
      const updatedPercentages = reductionPercentages.map((item) => {
        if (item.year === year) {
          // Update the specific scope within the matching year
          return {
            ...item,
            scopes: {
              ...item.scopes,
              [scopeKey]: Number(value)
            }
          };
        }
        return item;
      });
  
      setReductionPercentages(updatedPercentages);
  };
  
  return (
        <main>
              <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto" ref={startRef}> 

                <div className="mb-4">
                  {/* Title */}
                  <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                    {t("footPrint.reductionCommitment")}
                    {t("footPrint.paraElCalculo")}
                    {organizationName}
                    {t("footPrint.de")}
                    {calcualtionYear}
                  </h1>
                </div>
                {reductionCommitment.isFetching ?
            (<div className='flex justify-center'>
              <Spinner className='mt-4' animation="border" variant="dark" />
            </div>

            ) : (<>
                {respuestaPost.message !== "" && <div className='my-2'><Banner type={respuestaPost.type} open setOpen={true}>{respuestaPost.message}</Banner></div>}

                {/* tabla compromisos que tiene */}
                {(reductionId?.data?.data?.length > 0) &&
                  <>
                    <div className="overflow-x-auto w-full mb-8">
                      <div className="table-header" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <table className="table-striped table-auto w-full bg-white">
                          <thead className='text-center  text-xl sec-green-background text-white'>
                            <tr className='py-4'>
                              <th>{t("footPrint.compromisosAdquiridos")}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-body" style={{ maxHeight: '20vh', overflowY: 'auto' }}>
                        <table className="table-striped table-auto w-full bg-white">
                          <tbody className='text-start text-md'>
                            {reductionId.data.data != undefined &&
                              reductionId.data.data.map((x) => {
                                return (
                                  <tr className='py-4' key={x.id}>
                                    <td className='px-4'>
                                      {x.reductionCommitmentDto ? (
                                        x.reductionCommitmentDto.name
                                      ) : (
                                        <div dangerouslySetInnerHTML={{ __html: x?.text }} />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className='flex justify-center'>
                      {isModal ? 
                      <>
                        {!hasEmptyScopes(reductionPercentages) ?
                        <button className='btn-sm btn-banner-o rounded mb-4' onClick={handleReductionPlan} disabled={loadingPlan.isFetching}>
                          {loadingPlan.isFetching ? (
                              <div className="spinner-border" role="status">
                              </div>
                          ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                              </svg>
                          )}
                          <span className='ml-2'>Generar Plan de Reducción</span>
                        </button> : ""}
                        </>
                      :
                        <button className='btn-sm btn-banner-o rounded-lg mb-4' onClick={handleReport} disabled={loadingReport}>
                          {loadingReport ? (
                              <div className="spinner-border" role="status">
                              </div>
                          ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" strokeWidth="1.5" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                              </svg>
                          )}
                          <span className='ml-2'>{t("footPrint.reportWithRedComm")}</span>
                        </button>}
                    </div>
                  </>
                }
                    <form onSubmit={handleReduction}>
                    <div className='flex flex-col items-center mb-4'>
                      <div className='w-full'><h3 className='text-md text-left font-semibold mb-2'>Indica los porcentajes de reducción estimados a 3 años de la organización {organizationName}:</h3></div>
                      <div className="overflow-x-auto w-full table-body">
                      <table className="table-auto w-full bg-white">
                          <thead className='text-center  text-xl text-white'>
                            <th className='sec-neutro-background' />
                            <th className='px-4 sec-green-background border-right'>Alcance 1</th>
                            <th className='px-4 sec-green-background border-right'>Alcance 2</th>
                            {!isModal &&
                              <th className='px-4 sec-green-background '>Alcance 3</th>
                            }
                          </thead>
                          <tbody className='text-start text-md'>

                            {reductionPercentages.map((item) => {
                              return (
                                <tr className='border-bottom'>
                                  <td className='text-center px-4 border-right'>{item.year}</td>
                                  <td className='border-right'>
                                    <input type="number" required={isModal} min="0.1" max="100" step="0.1"defaultValue={item.scopes.scope1} className='text-center w-full' style={{ borderStyle: 'none' }} onChange={ (e) => handleReductionPercentage(e, item.year, 'scope1')} />
                                  </td>
                                  <td className='border-right'>
                                    <input type="number" required={isModal} min="0.1" max="100" step="0.1" defaultValue={item.scopes.scope2} className='text-center w-full' style={{ borderStyle: 'none' }} onChange={ (e) => handleReductionPercentage(e, item.year, 'scope2')} />
                                  </td>
                                  {!isModal &&
                                  <td>
                                    <input type="number" min="0.1" max="100" step="0.1" defaultValue={item.scopes.scope3} className='text-center w-full' style={{ borderStyle: 'none' }} onChange={ (e) => handleReductionPercentage(e, item.year, 'scope3')} />
                                  </td>
                                  }
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                
                <h2 className="text-md text-gray-800 font-semibold mb-2">{t("footPrint.seleccionaCompromisos")}</h2>
                <div className="overflow-x-auto w-full mb-4">
                  <div className="table-header" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <table className="table-striped table-auto w-full bg-white">
                      <thead className='text-center  text-xl sec-green-background text-white'>
                        <tr className='py-4'>
                          <th></th>
                          <th style={{ width: '64%' }}>
                            <button type="button" onClick={() => requestSort('name')} className={`${getClassNamesFor('name')} text-underline`}>
                              {t("footPrint.compromiso")}
                            </button>
                          </th>
                          <th className='text-center'>
                            <button type="button" onClick={() => requestSort('reductionCommitmentType')} className={`${getClassNamesFor('reductionCommitmentType')} text-underline`}>
                              {t("footPrint.tipo")}
                            </button>
                          </th>
                          <th className='text-center'>
                            <button type="button" onClick={() => requestSort('level')} className={`${getClassNamesFor('level')} text-underline`}>
                              {t("footPrint.level")}
                            </button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="table-body" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    <table className="table-striped table-auto w-full bg-white">
                      <tbody className='text-start text-md'>
                        {reductionCommitment.data.data != undefined &&
                          items.map((x) => {
                            return (
                              <tr className='py-4' key={x.id}>
                                <td className='w-12 px-4'>
                                  <input type="checkbox" id={`miCheckbox_${x.id}`} name="miCheckbox" checked={checkedItems.has(x.id)} onChange={() => handleCheckboxChange(x.id)} />
                                </td>
                                <td style={{ width: '60%' }}>{x.name}</td>
                                <td className='text-center' style={{ width: '20%' }}>{formatearTexto(x.reductionCommitmentType)}</td>
                                <td className='text-center'>{x.level}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="mb-4">
                  {/* Title */}
                  <h2 className="text-md mb-2 text-gray-800 font-semibold">{t("footPrint.addCompromisos")}</h2>
                  <div className='mb-4'>
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={handleEditorStateChange}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="customEditorClassName"
                      onChange={handleEditorChange}
                    />
                  </div>
                </div>

                <div className=' flex items-center mt-8  justify-center gap-4'>
                  {!isModal &&
                    <button arial-label="btn-back" className="btn-sm btn-cancel text-white ml-3" type='button' onClick={() => window.history.back()}  >
                      {t("footPrint.volver")}
                    </button>
                  }
                  {/* <button arial-label="btn-saveFom" className="btn-sm btn-green text-white " type='submit' onClick={() => handleReduction()}> */}
                  <button arial-label="btn-saveFom" className="btn-sm btn-green text-white " type='submit'>

                    {loading ? (
                      <div className="spinner-border" role="status" />
                    ) : (
                      <>{t("footPrint.guardar")}</>
                    )}
                  </button>
                </div>
                </form>
                </>)}

              </div>
          
          </main>
  )
}
