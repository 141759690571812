import React from 'react';

import Footer from '../../partials/Footer';
import Header from '../../partials/Header';
import Introducction from './componentsHome/Introducction';

import BannerCertificaciones from './componentsHome/BannerCertificaciones';
import BannerDemo from './componentsHome/BannerDemo';
import BannerForestal from './componentsHome/BannerForestal';
import BloqueClientes from './componentsHome/BloqueClientes';
import BloqueIconostxt from './componentsHome/BloqueIconostxt';
import BloqueImgSlide from './componentsHome/BloqueImgSlide';
import CommunityClub from './componentsHome/CommunityClub';
import SuccessStories from './componentsHome/SuccessStories';
export default function Home() {

  return (
    <div id='inicio'>
     
      <Header></Header>
      <Introducction></Introducction>

      <BloqueClientes></BloqueClientes>
      <BloqueIconostxt></BloqueIconostxt>
      <BloqueImgSlide></BloqueImgSlide>

      <BannerCertificaciones></BannerCertificaciones>
      <SuccessStories></SuccessStories>
      <CommunityClub></CommunityClub>
      <BannerForestal></BannerForestal>
      <BannerDemo></BannerDemo>

      <Footer></Footer>
    </div>
  )
}
