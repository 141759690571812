
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext"

import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.css';
import 'swiper/modules/a11y.css'
import 'swiper/modules/pagination.css';
import 'swiper/modules/scrollbar.css';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation.scss'; // Navigation module
import 'swiper/modules/pagination.scss'; // Pagination module
import valladolid from '../../../images/logo-valladolid.webp'
import coverwind from '../../../images/coverwind.webp'
import frutas from '../../../images/frutas-nieves.webp'
import ptmar from '../../../images/pereztorres.webp'
import atlas from '../../../images/atlas.webp'
import ontime from '../../../images/ontime.webp'
import coolberries from '../../../images/coolfarming2.webp'
import befla from '../../../images/befla.webp'

export default function BloqueClientes() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  let images = [coolberries, ptmar, ontime, valladolid, frutas, coverwind, atlas, befla]
  return (



    <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4">
      <div className="block md:hidden" data-aos="fade-down">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          speed={1000} 
          modules={[Autoplay, EffectFade]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            loop: true
          }}
          className="mySwiper"
          loop={true}
        >
          {images.map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img className="imagen-gris" src={imageUrl} alt={`Cliente ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="hidden md:block " data-aos="fade-down" data-aos-delay="400">
        <Swiper
          slidesPerView={7}
          spaceBetween={50}
          speed={1000} 
          modules={[Autoplay, EffectFade]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            loop: true
          }}
          className="mySwiper"
          loop={true}
        >
          {images.map((imageUrl, index) => (
            <SwiperSlide key={index} className='flex' >
              <img className="imagen-gris" src={imageUrl} alt={`Cliente ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>



  )
}
